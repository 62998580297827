import axios from "axios"
import { isLoggedIn, getUser, isBrowser } from "./auth"

const BASE_URL = "47.99.220.122:8000"
export const handleFire = async () => {
  if (!isLoggedIn()) return
  const accessToken = getUser().access
  console.log("You are in the step1 right now ")
  const pollsurl = BASE_URL + "/login/getFire"
  const method = "get"
  const headers = { Authorization: `Bearer ${accessToken}` }
  const response = await fetch(pollsurl, { method, headers })
  if (response.ok) {
    console.log("You are in the step2 right now")
    const response_object = await response.json()
    console.log(response_object)
    console.log("current point is", response_object.fire)
    // const response_json = JSON.parse(response_object)
    // console.log("inside getMapTable current data is ", response_object)
    return response_object
  } else {
    console.error("could not get the record ")
  }
}

export const handleEnergy = async () => {
  if (!isLoggedIn()) return
  const accessToken = getUser().access
  console.log("You are in the step1 right now ")
  const pollsurl = BASE_URL + "/timer/getPoint"
  const method = "get"
  const headers = { Authorization: `Bearer ${accessToken}` }
  const response = await fetch(pollsurl, { method, headers })
  if (response.ok) {
    console.log("You are in the step2 right now")
    const response_object = await response.json()
    // console.log(response_object[0])
    // console.log("current point is", response_object[0].point)
    // const response_json = JSON.parse(response_object)
    // console.log("inside getMapTable current data is ", response_object)
    return response_object[0].point
  } else {
    console.error("could not get the record ")
  }
}
// export const handleMapTable = async () => {
//   if (!isLoggedIn()) return
//   const accessToken = getUser().access
//   console.log("You are in the step1 right now ")
//   const pollsurl = "http://localhost:3000/login/getTableRecord"
//   const method = "get"
//   const headers = { Authorization: `Bearer ${accessToken}` }
//   const response = await fetch(pollsurl, { method, headers })
//   if (response.ok) {
//     const response_object = await response.json()
//     console.log("handleMapTable is", response_object)
//     // const response_json = JSON.parse(response_object)
//     // console.log("inside getMapTable current data is ", response_object)
//     return response_object
//   } else {
//     console.error("could not get the record ")
//   }
// }

export const handleHuntData = async () => {
  if (!isLoggedIn()) return
  const accessToken = getUser().access
  console.log("You are in the step1 right now ")
  const pollsurl = BASE_URL + "/login/getHunt"
  const method = "get"
  const headers = { Authorization: `Bearer ${accessToken}` }
  const response = await fetch(pollsurl, { method, headers })
  if (response.ok) {
    const response_object = await response.json()
    console.log("handleHuntTable is", response_object)
    // const response_json = JSON.parse(response_object)
    // console.log("inside getMapTable current data is ", response_object)
    return response_object
  } else {
    console.error("could not get the record ")
  }
}

export const handleMap = async () => {
  const accessToken = getUser().access
  const pollsurl = BASE_URL + "/login/getMap"
  const method = "get"
  const headers = { Authorization: `Bearer ${accessToken}` }
  const response = await fetch(pollsurl, { method, headers })
  if (response.ok) {
    const record_json = await response.json()
    console.log("inside handleMap current data is ", record_json)
    // setMap(record_json)
    return record_json[0]
  } else {
    console.error("could not get the record ")
  }
}

export const handleTodayRecord = async () => {
  const accessToken = getUser().access
  const pollsurl = BASE_URL + "/login/getTodayRecord"
  const method = "get"
  const headers = { Authorization: `Bearer ${accessToken}` }
  const response = await fetch(pollsurl, { method, headers })
  if (response.ok) {
    const record_json = await response.json()
    console.log("handleTodayRecord is !!!!!!!!!!!!!!!!", record_json)
    // console.log("inside handleTodayRecord current data is ", record_json)
    // setTodayRecord(record_json)
    return record_json
  } else {
    console.error("could not get the record ")
  }
}
