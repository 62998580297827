import * as React from "react"
import { Navbar } from "react-bootstrap"
import { useState, useEffect } from "react"
import { handleEnergy, handleFire } from "../services/api"
import NavBar from "../components/navbar"
import { isLoggedIn, refreshToken, getUser } from "../services/auth"
import Button from "react-bootstrap/Button"
const BASE_URL = "47.99.220.122:8000"
export default function MakeFire() {
  const handleSubmit = event => {
    event.preventDefault()
    const accessToken = getUser().access
    ;(async () => {
      const pollsurl = BASE_URL + "/login/makefire"
      const method = "put"
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
      const response = await fetch(pollsurl, { method, headers })
      const response_data = await response.json()
      console.log(response_data)
      if (response.ok) {
        alert(response_data.message)
        window.location.reload(false)
      } else {
        console.log("some bug in your findview")
      }
    })()
  }

  const [firedata, setFiredata] = useState(0)
  const [mastery, setMastery] = useState(0)
  const [energypoint, setEnergypoint] = useState(0)
  const [rock, setRock] = useState(0)

  useEffect(() => {
    refreshToken()
    if (!isLoggedIn()) return
    ;(async () => {
      const [fire, energy] = await Promise.all([handleFire(), handleEnergy()])
      setMastery(fire.mastery)
      setEnergypoint(energy)
      setRock(fire.rock)
      setFiredata(fire.fire)
    })()
  }, [
    handleFire,
    handleEnergy,
    setMastery,
    setEnergypoint,
    setRock,
    setFiredata,
  ])
  if (rock) {
    return (
      <div>
        <NavBar />
        <div className="historycenter">
          <h1>生火</h1>
          <p>当前生火熟练度：{mastery}</p>
          <p>当前营火状态：{firedata ? "点燃" : "熄灭"}</p>
          <img src="../images/campfire.png" style={{ width: "25%" }} />
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            生火
          </Button>
          <p style={{ padding: "30px" }}>当前行动点：{energypoint}</p>
          <p></p>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <NavBar />
        <div className="historycenter">
          <h1>生火</h1>
          <p style={{ padding: "120px" }}>当前无任何生火工具</p>
          <p style={{ padding: "30px" }}>当前行动点：{energypoint}</p>
          <p></p>
        </div>
      </div>
    )
  }
}
